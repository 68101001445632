import React, {useState} from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css';
import logo from './images/logo.png'
import useCheckAge from "./functions/checkAge";

const NavbarB = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMenu = () => setClick(false);

  useCheckAge();

  return (
    <div className={`header fixed`}>
      <nav className='navbar'>
        <a href='/' className='logo'>
          <img className="headertitle" src={logo} alt="MDN"/>
        </a>
        <div className='hamburger' onClick={handleClick}>
          {click ? <FaTimes size={30} style={{ color: '#000000' }} /> : <FaBars size={30} style={{ color: '#000000' }} />}
        </div>
        <div className={click ? 'nav-menu active' : 'nav-menu'}>
          <ul className='menu-items'>
            <li className='nav-item'>
              <a href='/' onClick={closeMenu}>
                Inicio
              </a>
            </li>
            <li className='nav-item'>
              <a href='/wine' onClick={closeMenu}>
                Nuestro Vino
              </a>
            </li>
            <li className='nav-item'>
              <a href='/aboutUs' onClick={closeMenu}>
                Equipo
              </a>
            </li>
            <li className='nav-item'>
              <a href='/Shop' onClick={closeMenu}>
                Tienda Online
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default NavbarB;
