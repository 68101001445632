import React, { useEffect, useRef } from 'react';
import './Video.css';
import video from './images/promo.mp4';
//"https://player.vimeo.com/video/861143388?h=b59303511b"

const Video = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        const playPromise = videoRef.current.play();

        if (playPromise !== undefined) {
            playPromise.then(_ => {
                // La reproducción automática se inició correctamente.
            }).catch(error => {
                // La reproducción automática falló.
                console.error('Error al iniciar la reproducción automática:', error);
            });
        }
    }, []);

    return (
        <div className='video'>
            <video ref={videoRef} src={video} autoPlay muted loop>
                Tu navegador no admite el elemento <code>video</code>.
            </video>
        </div>
    );
};

export default Video;
