import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardContent, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import headerIMG from '../images/header-wine.jpg';
import NavbarB from '../NavbarB';
import "../common.css"
import "./shop.css"
import Footer from "../Footer";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {useNavigate} from "react-router-dom";

const Shop = () => {
  // Estados para las cantidades de cada card
  const [shippingCosts, setShippingCosts] = useState([]);
  const [products, setProducts] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [csrftoken, setCsrftoken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Obtener productos del backend
    const fetchProducts = async () => {
      try {
        const response = await fetch('http://217.160.115.139:8001/api/products/', {
          method: 'GET',
        });
        if (!response.ok) {
          throw new Error('Error al obtener los productos');
        }
        const data = await response.json();
        setProducts(data);
        const initialQuantities = {};
        data.forEach(product => {
          initialQuantities[product.product_id] = 0; // Inicializar cantidades a 0
        });
        setQuantities(initialQuantities);
      } catch (error) {
        console.error('Error al obtener los productos:', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    // Obtener productos del backend
    const fetchShipping = async () => {
      try {
        const response = await fetch('http://217.160.115.139:8001/api/shipping/', {
          method: 'GET',
        });
        if (!response.ok) {
          throw new Error('Error al obtener los costes de envio');
        }
        const data = await response.json();
        setShippingCosts(data);
      } catch (error) {
        console.error('Error al obtener los productos:', error);
      }
    };

    fetchShipping();
  }, []);

  useEffect(() => {
    const getCSRFToken = async () => {
      try {
        const response = await fetch('http://217.160.115.139:8001/api/get-csrf-token/', {
          method: 'GET',
          credentials: 'include',
        });
        if (!response.ok) {
          throw new Error('No se pudo obtener el token CSRF');
        } else {
            const cookies = document.cookie.split(';');
            const csrfCookie = cookies.find(cookie => cookie.trim().startsWith('csrftoken='));
            if (csrfCookie) {
              setCsrftoken(csrfCookie.split('=')[1]);  // Extraer y guardar el token CSRF de la cookie
            }
        }
      } catch (error) {
        console.error('Error al obtener el token CSRF:', error);
      }
    };

    getCSRFToken();
  }, []);  // Solo se ejecuta al montar el componente

  const handleIncrease = (productId) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: prevQuantities[productId] + 1,
    }));
  };

  const handleDecrease = (productId) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: Math.max(prevQuantities[productId] - 1, 0),
    }));
  };

  const initiatePayment = async () => {
    const selectedProducts = products.map(product => ({
      product_id: product.product_id,
      quantity: quantities[product.product_id],
      name: product.name,
      price: product.price
    })).filter(product => product.quantity > 0);

    try {
      const response = await fetch('http://217.160.115.139:8001/api/payment/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify(selectedProducts),
      });
      if (!response.ok) {
        console.error('Error al iniciar el pago:', response.status);
        return;
      }
      const data = await response.text();
      const orderNumber = response.headers.get('order-number');
      navigate('/shipping-form', { state: { paymentData: data, products: selectedProducts, orderNumber: orderNumber, shippingCosts: shippingCosts } });
    } catch (error) {
      console.error('Error al iniciar el pago:', error);
    }
  };


  return (
    <div>
    <Box sx={{ bgcolor: '#2B2B2B', minHeight: '100vh' }}>
      {/* Cabecera con imagen */}
      <Box
        sx={{
          background: `url(${headerIMG}) center/cover no-repeat`,
          height: '40vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Título en dorado encima de las Cards */}
        <Typography
          variant="h1"
          sx={{
            color: '#b68d2c',
            fontFamily: "'Marcellus', serif",
            fontWeight: '100',
            textShadow: '2px 2px 8px rgba(0, 0, 0, 0.4)',
          }}
        >
          Animam Viventem
        </Typography>
      </Box>

      {/* Conjunto de Cards */}
      <Grid container spacing={4} justifyContent="center" sx={{ marginTop: 6 }}>
        {/* Card 1: Una botella */}
        {products.map((product) => (
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              padding: 4,
              textAlign: 'center',
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
              bgcolor: "#EEEEEEFF"
            }}
          >
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 2 }}>
              {product.name}
            </Typography>
            <Box
              component="img"
              src={`/${product.img}.png`}
              alt={product.name}
              sx={{
                width: 'auto',
                height: '300px',
                margin: '0 auto',
                marginBottom: 2,
                borderRadius: 4,
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
              }}
            />
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  onClick={() => handleDecrease(product.product_id)}
                  sx={{ color: 'darkred' }}
                >
                  <RemoveIcon />
                </IconButton>
                <Typography variant="h4" sx={{ mx: 2 }}>
                  {quantities[product.product_id]}
                </Typography>
                <IconButton
                  onClick={() => handleIncrease(product.product_id)}
                  sx={{ color: 'darkgreen' }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                  <div className="prices">
                      {product.price * quantities[product.product_id]} €
                  </div>
                  <IconButton
                      sx={{
                        color: 'maroon'
                      }}
                      aria-label="add to cart"
                      onClick={initiatePayment}
                    >
                        <ShoppingCartIcon />
                  </IconButton>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        ))}
      </Grid>
    </Box>
    <NavbarB/>
    <Footer />
    </div>
  );
};

export default Shop;
