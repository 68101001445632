import * as React from 'react';
import "./ShippingForm.css";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import NavbarB from "../NavbarB";
import Footer from "../Footer";
import {Box, Link, TextField, Typography} from "@mui/material";
import { useLocation } from 'react-router-dom';
import headerIMG from "../images/header-wine.jpg";
import ShippingCart from "./ShippingCart";
import {useEffect, useState} from "react";

const ShippingForm = () => {
  const location = useLocation();
  const paymentData = location.state?.paymentData;
  const products = location.state?.products;
  const shippingCosts = location.state?.shippingCosts;
  const orderNumber = location.state?.orderNumber;

  // Estado para los valores del formulario
  const [formValues, setFormValues] = useState({
    name: '',
    address: '',
    postalCode: '',
    emailAddress: '',
    privacyPolicy: false
  });

  // Estado para los errores del formulario
  const [formErrors, setFormErrors] = useState({
    nameError: false,
    addressError: false,
    postalCodeError: false,
    emailAddressError: false,
    privacyPolicyError: false,
  });

  const [formEmpty, setFormEmpty] = useState({
    nameEmpty: true,
    addressEmpty: true,
    postalCodeEmpty: true,
    emailAddressEmpty: true,
    privacyPolicyEmpty: true,
  });

  // Función para validar los campos
  const validateField = (name, value) => {
    switch (name) {
      case 'postalCode':
        const postalCodeRegex = /^[0-9]{5}$/;
        return !(postalCodeRegex.test(value) && value >= 1000 && value <= 52999);
      case 'emailAddress':
        console.log("Field: ", name, " ", value)
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(value.match(validRegex)) {
            return false;
        } else {
            return true;
        }
      case 'name':
      case 'address':
        console.log("Field: ", name)
        console.log("Error: ", value.trim() === '')
        return value.trim() === '';
      case 'privacyPolicy':
        return !value;
      default:
        return false;
    }
  };

  // Manejador de cambio de entrada para todos los campos
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === 'checkbox' ? checked : value;

    setFormValues({
      ...formValues,
      [name]: newValue,
    });

    setFormErrors({
      ...formErrors,
      [`${name}Error`]: validateField(name, newValue),
    });

    setFormEmpty({
      ...formEmpty,
      [`${name}Empty`]: false,
    });
  };

  const sendPaymentData = async () => {
      const response = await fetch('http://217.160.115.139:8001/api/payment/' + orderNumber, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formValues.name,
          address: formValues.address,
          postalCode: formValues.postalCode,
          emailAddress: formValues.emailAddress,
        }),
      });

      if (!response.ok) {
        return false;
      }
      return true;
  };

  useEffect(() => {
    if (paymentData) {
      const formElement = document.querySelector("form");
      if (formElement) {
        const handleFormSubmit = async (e) => {
            e.preventDefault();
            let errors = (formErrors.nameError || formErrors.addressError || formErrors.postalCodeError || formErrors.emailAddressError || formErrors.privacyPolicyError)
            let empty = (formEmpty.nameEmpty || formEmpty.addressEmpty || formEmpty.postalCodeEmpty || formEmpty.emailAddressEmpty || formEmpty.privacyPolicyEmpty)
            if (!errors && !empty) {
                const postSuccess = await sendPaymentData();
                if (postSuccess) {
                  formElement.submit();
                }
            }
        };

        formElement.addEventListener('submit', handleFormSubmit);
        return () => {
          formElement.removeEventListener('submit', handleFormSubmit);
        };
      }
    }
  }, [paymentData, formErrors, formEmpty]);

  return (
    <div className={"backgroundForm"}>
      <Box
        sx={{
          background: `url(${headerIMG}) center/cover no-repeat`,
          height: '40vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: '#b68d2c',
            fontFamily: "'Marcellus', serif",
            fontWeight: '100',
            textShadow: '2px 2px 8px rgba(0, 0, 0, 0.4)',
          }}
        >
          Animam Viventem <div className="bottleYearTitle">2020</div>
        </Typography>
      </Box>
      <div className={"formClass"}>
        {products && (
          <div>
            <ShippingCart products={products} shippingCosts={shippingCosts} />
          </div>
        )}
        <TextField
          required
          margin="dense"
          className={"textField"}
          id="name"
          name="name"
          color="success"
          label="Nombre"
          variant="outlined"
          value={formValues.name}
          onChange={handleInputChange}
          error={formErrors.nameError}
          helperText={formErrors.nameError ? 'El nombre no debe estar vacío.' : ''}
        />
        <TextField
          required
          margin="dense"
          className={"textField"}
          id="emailAddress"
          name="emailAddress"
          color="success"
          label="Dirección de correo"
          variant="outlined"
          value={formValues.emailAddress}
          onChange={handleInputChange}
          error={formErrors.emailAddressError}
          helperText={formErrors.emailAddressError ? 'Email invalido.' : ''}
        />
        <TextField
          required
          margin="dense"
          className={"textField"}
          id="address"
          name="address"
          color="success"
          label="Dirección"
          variant="outlined"
          value={formValues.address}
          onChange={handleInputChange}
          error={formErrors.addressError}
          helperText={formErrors.addressError ? 'La dirección no debe estar vacía.' : ''}
        />
        <TextField
          required
          margin="dense"
          className={"textField"}
          id="postalCode"
          name="postalCode"
          color="success"
          label="Código Postal"
          variant="outlined"
          value={formValues.postalCode}
          onChange={handleInputChange}
          error={formErrors.postalCodeError}
          helperText={formErrors.postalCodeError ? 'Código postal inválido.' : ''}
        />
        <FormControlLabel required control={<Checkbox />} onChange={handleInputChange} name="privacyPolicy" label={
            <>
              He leído y acepto la{' '}
              <Link href="/privacy-policy" target="_blank" rel="noopener">politica de privacidad</Link>
            </>
          }
        />
        {paymentData ? (
          <div dangerouslySetInnerHTML={{ __html: paymentData }} />
        ) : (
          <p>No hay datos de pago disponibles.</p>
        )}
      </div>
      <NavbarB />
      <Footer />
    </div>
  );
};

export default ShippingForm;
