import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css';
import logo from './images/logo.png'

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [scroll, setScroll] = useState(0);

  const handleClick = () => setClick(!click);
  const closeMenu = () => setClick(false);

  const [scrollValue, setScrollValue] = useState(375);

  useEffect(() => {
    const root = document.documentElement;
    const computedStyle = getComputedStyle(root);
    const viewportHeight = window.innerHeight;
    const scrollValue = viewportHeight * 0.45;
    const screenWidth = window.innerWidth;

    document.documentElement.style.setProperty('--screen-width', `${screenWidth}px`);
    console.log(screenWidth);

    setScrollValue(scrollValue);

    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    // Limpia el evento de scroll al desmontar el componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`header ${scroll > scrollValue ? 'fixed' : ''}`}>
      <nav className='navbar'>
        <a href='/' className='logo'>
          <img className="headertitle" src={logo} alt="MDN"/>
        </a>
        <div className='hamburger' onClick={handleClick}>
          {click ? <FaTimes size={30} style={{ color: '#000000' }} /> : <FaBars size={30} style={{ color: '#000000' }} />}
        </div>
        <div className={click && scroll > scrollValue ? 'nav-menu active' : 'nav-menu'}>
          <ul className='menu-items'>
            <li className='nav-item'>
              <a href='/' onClick={closeMenu}>
                Inicio
              </a>
            </li>
            <li className='nav-item'>
              <a href='/wine' onClick={closeMenu}>
                Nuestro Vino
              </a>
            </li>
            <li className='nav-item'>
              <a href='/aboutUs' onClick={closeMenu}>
                Equipo
              </a>
            </li>
            <li className='nav-item'>
              <a href='/Shop' onClick={closeMenu}>
                Tienda Online
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
