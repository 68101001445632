import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useCheckAge = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAdult = document.cookie.split('; ').find(row => row.startsWith('isAdult='));
    if (!isAdult || isAdult.split('=')[1] !== 'true') {
      // Si la cookie no existe o no es 'true', redirigir a la página principal
      navigate('/');
    }
  }, [navigate]);
};

export default useCheckAge;