import React, {useEffect} from 'react';
import { Box, Grid, Typography } from '@mui/material';
import headerIMG from '../images/cabecera.jpg';
import glass from '../images/glass.jpg';
import wine from '../images/wine.jpg';
import NavbarB from "../NavbarB";
import "../common.css"
import "./wine.css"
import Footer from "../Footer";
import video from "../images/botella.mp4";
import { useState } from 'react';

const Wine = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);

  useEffect(() => {
    // Bloquear el scroll mientras el video está activo
    if (isVideoPlaying) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Limpieza al desmontar el componente
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVideoPlaying]);

  useEffect(() => {
    const handleScroll = () => {
      document.body.style.setProperty(
        "--scroll",
        window.scrollY / (document.body.offsetHeight - window.innerHeight)
      );
    };

    window.addEventListener('scroll', handleScroll);

    // Limpia el evento de scroll al desmontar el componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleVideoEnd = () => {
    setIsVideoPlaying(false);
  };

  return (
      <Box className="black-background" sx={{padding: 0, color: '#E7E3E1'}}>
          {isVideoPlaying && (
            <div className='bottleVideo'>
                <video onEnded={handleVideoEnd} src={video} autoPlay muted>
                  Tu navegador no admite el elemento <code>video</code>.
                </video>
            </div>
          )}
          {/* Primera Sección: Título Grande con Fondo */}
          <Box
              sx={{
                  background: `url(${headerIMG}) center/cover no-repeat`,
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '40vh',
              }}
          >
              <Typography
                  variant="h1"
                  sx={{
                      color: 'maroon',
                      fontFamily: "'Marcellus', serif",
                      fontWeight: '100',
                      textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
                      letterSpacing: '4px',
                  }}
              >
                  Vino
              </Typography>
          </Box>

          <Grid container alignItems="center"
              sx={{
                marginLeft: { lg: "10vw" },
                marginTop: { lg: "10vh" },
                maxWidth: "100vw",
                width: "auto",
                gap: "5vw"
              }}
          >
              <Grid item xs={12} md={6}>
                  <Typography
                      variant="h4"
                      sx={{
                          fontFamily: "'Marcel', sans-serif",
                          fontWeight: 1000,
                          marginBottom: 2
                      }}
                  >
                      Ficha de cata
                  </Typography>
                  <Typography variant="body1"
                              sx={{fontFamily: "'Montserrat', sans-serif", fontWeight: 600}}>
                      <p>ANIMAM VIVENTEM 2020 procede de viñedos ubicados en la zona más septentrional de la Ribera
                          del Duero burgalesa, en Tórtoles de Esgueva.</p>
                      <p></p>
                      <p>Las uvas, de la variedad Tempranillo, son seleccionadas de viñedos de más de 25 años, con
                          rendimientos bajos, para garantizar la máxima calidad de los mostos.</p>
                      <p></p>
                      <p>El viñedo se encuentra a 960 metros de altitud y se caracteriza por un suelo pedregoso con
                          fondo calizo, que, gracias a sus diversas exposiciones, permite expresar de manera
                          destacada el terroir de la zona.</p>
                      <p></p>
                      <p>En cuanto a la elaboración, la vendimia se realiza manualmente con selección de racimos
                          tanto en el campo, como en la bodega. Tras el despalillado y un leve estrujado, se lleva a
                          cabo una maceración prefermentativa a 11 grados durante un período de 24 a 48 horas. La
                          fermentación alcohólica se controla meticulosamente.</p>
                      <p></p>
                      <p>Posteriormente, el vino envejece y madura entre 13 y 15 meses en barricas de roble,
                          predominantemente francés (95%) y en menor medida americano (5%), de diferentes tamaños
                          (225L, 300L y 500L).</p>
                      <p></p>
                      <p>Finalmente, el vino reposa en botella durante un mínimo de 12 meses antes de llegar a su
                          mesa.</p>
                  </Typography>
              </Grid>
              <Grid item xs={12} md={6}
                  sx={{
                    marginLeft: { lg: "10vw" },
                    maxWidth: { lg: "20vw" }
                  }}
              >
                  <video
                      autoPlay
                      muted
                      loop
                      src={video}
                      className="bottle-little-video"
                  />
              </Grid>
          </Grid>

          {/* Segunda Sección: Imágenes flotantes con texto */}
          <Grid container spacing={4} alignItems="center"
            sx={{
                marginLeft: { lg: "7.5vw" },
                marginTop: { lg: "10vh" },
                maxWidth: "100vw",
                width: "auto",
                gap: "7.5vw"
            }}>
              <Grid item
                sx={{
                    maxWidth: "45vw"
                }}
              >
                  <Box
                      component="img"
                      src={glass}
                      alt="Imagen izquierda"
                      sx={{
                          width: 'auto',
                          height: '400px',
                          borderRadius: 2,
                          transition: 'transform 0.3s ease-in-out',
                          '&:hover': {
                              transform: 'scale(1.1)',
                          },
                      }}
                  />
              </Grid>
              <Grid className="wineTextSection" item
                sx={{
                    maxWidth: { lg: "35vw" }
                }}
              >

                  <Typography variant="h5" gutterBottom sx={{
                      fontFamily: "'Montserrat', sans-serif",
                      fontWeight: 1000,
                      marginBottom: 2
                  }}>
                      Una Historia en cada copa
                  </Typography>
                  <div className="wineTextSection">
                      Nota de cata:
                      <ul>
                          <li>
                              Fase Visual:
                              <p>
                                  Cereza picota, limpio y brillante.
                              </p>
                          </li>
                          <li>
                              Nariz:
                              <p>
                                  Nariz compleja con intenso aroma a fruta roja madura (fresas, frambuesas...) que le
                                  otorga frescura
                                  y un fondo ligero de tostados, especias y toffee.
                              </p>
                          </li>
                          <li>
                              Boca:
                              <p>
                                  Entrada suave y fresca, fácil de beber. Taninos sedosos de carácter frutal. Destaca la
                                  persistencia
                                  final, manteniendo la fruta durante un tiempo prolongado.
                              </p>
                          </li>
                      </ul>
                  </div>
              </Grid>
          </Grid>

          {/* Tercera Sección: Sección alterna con fondo oscuro */}
              <Grid className="wineTextSection" container spacing={4} alignItems="center"
                sx={{
                    marginLeft: { lg: "10vw" },
                    marginTop: { lg: "10vh" },
                    maxWidth: "100vw",
                    width: "auto",
                    gap: "5vw"
                }}
              >
                  <Grid item xs={12} md={8}
                    sx={{
                        maxWidth: { lg: "40vw" },
                    }}
                  >
                      <Typography variant="h5" gutterBottom
                                  sx={{fontFamily: "'Montserrat', sans-serif", fontWeight: 1000, marginBottom: 2}}>
                          El Terroir
                      </Typography>
                      <Typography variant="body1" sx={{fontFamily: "'Montserrat', sans-serif", fontWeight: 600}}>
                          Tórtoles de Esgueva, ubicado en la provincia de Burgos, Castilla y León, es una zona con un
                          terreno excepcional para el cultivo de la vid. La región se beneficia de un clima continental
                          con influencias atlánticas, caracterizado por veranos calurosos e inviernos fríos, lo cual es
                          muy favorable para la viticultura.
                      </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}
                    sx={{
                        marginLeft: { lg: "10vw" },
                        maxWidth: { lg: "30vw" }
                    }}
                  >
                      <Box
                          className="wineTextSection"
                          component="img"
                          src={wine}
                          alt="Imagen derecha"
                          sx={{
                              borderRadius: 2,
                              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                              transition: 'transform 0.3s ease-in-out',
                              '&:hover': {
                                  transform: 'scale(1.1)',
                              },
                          }}
                      />
                  </Grid>
              </Grid>

          {/* Cuarta Sección: Imagen grande con texto superpuesto */}
          <Box
              sx={{
                  position: 'relative',
                  marginTop: 6,
                  overflow: 'hidden',
                  borderRadius: 4,
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
              }}
          >
              <Box
                  component="img"
                  src={headerIMG}
                  alt="Imagen centro"
                  sx={{width: '100%', maxHeight: '400px', objectFit: 'cover', opacity: 0.9}}
              />
              <Box
                  sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                  }}
              >
                  <Typography
                      variant="h3"
                      sx={{
                          color: '#b68d2c',
                          fontFamily: "'Marcellus', serif",
                          fontWeight: '100',
                          letterSpacing: '2px',
                          textAlign: 'center',
                          textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
                      }}
                  >
                      ANIMAM VIVENTEM <div className="bottleYearTitle">2020</div>
                  </Typography>
              </Box>
          </Box>
          <NavbarB/>
          <Footer/>
      </Box>

  );
};

export default Wine;
