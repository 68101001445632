import React, { useState, useEffect } from 'react';
import alfonso from './images/alfonso.jpg';
import macrino from './images/ribera.png';
import { Animator, MoveIn, Move } from 'react-scroll-motion';
import './About.css';
import {useNavigate} from "react-router-dom";

export default function Lawyer(props) {
  const [lawyerData, setLawyerData] = useState(null);
  const id = props.id;

  const navigate = useNavigate(); // Hook para la navegación

  const handleOnClick = () => {
    navigate('/aboutUs');
  };

  useEffect(() => {
    const fetchLawyerData = async () => {
      try {
        const response = await fetch(`http://217.160.115.139:8001/api/lawyers/${id}`);
        const data = await response.json();
        setLawyerData(data);
      } catch (error) {
        console.error('Error fetching lawyer data:', error);
      }
    };

    fetchLawyerData();
  }, [id]);

  if (!lawyerData) {
    return <p>Loading...</p>;
  }

  let { text, subtitle, name, photo_place } = lawyerData;
  photo_place = String(photo_place);

  return (
    <div className='about' id='about'>
      <div className='container'>
        {/* Condicionalmente mover la imagen hacia la derecha o izquierda */}
        {photo_place === "0" ? (
            <Animator animation={Move(-500, 0, 0, 0)}>
              <img src={alfonso} alt='john' className="slide-right" onClick={handleOnClick}/>
            </Animator>
        ) : null}

        <div className='col-2'>
          <h2 className="lawyer_name">{name}</h2>
          <span className='line'></span>
          <p className="lawyer_subtitle">{subtitle}</p>
          <p className="lawyer_text">{text}</p>
        </div>

        {photo_place === "1" ? (
            <Animator animation={MoveIn(1000, 0)}>
              <img src={macrino} alt='john' className="slide-left" onClick={handleOnClick}/>
            </Animator>
        ) : null}
      </div>
    </div>
  );
}
