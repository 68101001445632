import React, { useState, useEffect } from 'react';

const AgePopUp = () => {
  const [isVisible, setIsVisible] = useState(true);

  // Bloquear scroll cuando el popup esté visible
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isVisible]);

  const handleYes = () => {
    // Crear cookie con el valor de aceptación
    document.cookie = "isAdult=true; max-age=" + 60 * 60 * 24 * 365; // Cookie por 1 año
    // Reactivar navegación y ocultar el popup
    setIsVisible(false);
  };

  const handleNo = () => {
    // Redirigir o mostrar mensaje si el usuario no es mayor de edad
    alert('Debes ser mayor de edad para continuar.');
  };

  if (!isVisible) {
    return null; // Si el popup está oculto, no renderizar nada
  }

  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
        <h2 style={styles.title}>¿Eres mayor de edad?</h2>
        <div style={styles.buttons}>
          <button onClick={handleYes} style={styles.button}>Sí</button>
          <button onClick={handleNo} style={styles.button}>No</button>
        </div>
      </div>
    </div>
  );
};

// Estilos en línea
const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fondo semitransparente
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
  popup: {
    backgroundColor: '#000',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    color: '#fff',
    width: '300px',
  },
  title: {
    marginBottom: '20px',
    fontSize: '20px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#fff',
    color: '#000',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  }
};

export default AgePopUp;
