import React from 'react';
import './PhilosophySection.css'; // El archivo CSS que vamos a crear para estilos personalizados
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import {useNavigate} from "react-router-dom";

const PhilosophySection = ({ title, subtitle, alignment, img }) => {
  const navigate = useNavigate(); // Hook para la navegación

  const handleCardClick = () => {
    navigate('/philosophy'); // Redirige al componente Wine
  };

  return (
    <ThemeProvider
      theme={{
        palette: {
          primary: {
            main: 'rgba(92,12,12,0.62)',
            dark: '#000000',
          },
        },
      }}
    >
      <Box
        className="philosophy-section"
        display="flex"
        flexDirection={alignment === 'right' ? 'row-reverse' : 'row'}
        alignItems="center"
      >
        {img && (
          <Box onClick={handleCardClick} className="philosophy-image-container" flex={1}>
            <img src={img} alt="Philosophy" className="philosophy-image" />
          </Box>
        )}
        <Box className="letras-container" left={alignment === 'right' ? '-20%' : '50%'}>
          <h1 className="philosophy-title">{title}</h1>
          {subtitle && <p className="philosophy-description">{subtitle}</p>}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PhilosophySection;
