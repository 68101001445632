import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import founder1 from './images/macrino.jpg'; // Importar las imágenes
import founder2 from './images/alfonso.jpg';
import founderTogether from './images/founders.jpg';
import vision from './images/phil1.jpg';
import NavbarB from "./NavbarB";
import headerIMG from "./images/equipoHeader.jpg";
import Footer from "./Footer";


const FounderImage = styled('img')({
  width: 'auto',
  height: '500px',
  borderRadius: '8px',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(212, 175, 55, 0.6)', // Efecto dorado en hover
  },
});

const AboutUs = () => {
  return (
    <Box sx={{ backgroundColor: '#2B2B2B', padding: 0, minHeight: '100vh' }}>
    {/* Primera Sección: Título Grande con Fondo */}
      <Box
        sx={{
          background: `url(${headerIMG}) center/cover no-repeat`,
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '40vh',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: 'maroon',
            fontFamily: "'Marcellus', serif",
            fontWeight: '100',
            textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
            letterSpacing: '4px',
          }}
        >
          Equipo
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: '#2B2B2B', padding: 4, minHeight: '100vh' }}>
          {/* Sección de fundadores */}
          <Grid container spacing={6} sx={{ marginBottom: 6 }}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: '#2B2B2B',
                  padding: 4,
                  borderRadius: 2,
                }}
              >
                <Typography variant="h4" sx={{ fontFamily: "'Montserrat', sans-serif", color: 'maroon', fontWeight: '1000', marginBottom: 2 }}>
                  Victor Macrino
                </Typography>
                <Typography variant="body1" sx={{ color: 'white', fontFamily: "'Montserrat', sans-serif", fontSize: '1.1rem', marginBottom: 3 }}>
                  Con una trayectoria destacada en el mundo de la enología, Víctor ha trabajado en diversas bodegas, acumulando una vasta experiencia y un profundo conocimiento del arte de hacer vino. Su sueño siempre fue crear su propio vino, y encontró en Tórtoles de Esgueva, una zona desconocida de la Ribera del Duero, el lugar perfecto para hacerlo realidad. Apasionado por cada etapa del proceso, desde la viña hasta la copa, Víctor pone en cada botella no solo su experiencia, sino también su corazón y alma, buscando siempre la excelencia y la autenticidad en cada vino que elabora.
                </Typography>
                <FounderImage src={founder1} alt="Imagen del Fundador 1" />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: '#2B2B2B',
                  padding: 4,
                  borderRadius: 2,
                }}
              >
                <Typography variant="h4" sx={{ fontFamily: "'Montserrat', sans-serif", color: 'maroon', fontWeight: '1000', marginBottom: 2 }}>
                  Alfonso Pelayo Tejo
                </Typography>
                <Typography variant="body1" sx={{ color: 'white', fontFamily: "'Montserrat', sans-serif", fontSize: '1.1rem', marginBottom: 3 }}>
                  Economista de carrera y consultor financiero de profesión, Alfonso aporta al proyecto una visión estratégica y una sólida base financiera. Su pasión por el vino y la amistad con Víctor le llevaron a unirse a esta aventura enológica. Alfonso no solo comparte el amor por el vino, sino también la dedicación y el compromiso con la calidad. Su enfoque meticuloso y su
    habilidad para manejar los aspectos financieros son fundamentales para el éxito del proyecto, permitiendo que la creatividad y la experiencia de Víctor se traduzcan en vinos excepcionales.
    En Tórtoles de Esgueva, hemos unido nuestras fuerzas y nuestras pasiones para crear un proyecto único, donde cada botella refleja nuestro esfuerzo conjunto, nuestra amistad y nuestro amor por el buen vino. Queremos que cada persona que pruebe nuestros vinos sienta la dedicación y el cuidado que ponemos en cada detalle.
                </Typography>
                <FounderImage src={founder2} alt="Imagen del Fundador 2" />
              </Box>
            </Grid>
          </Grid>

          {/* Sección con imagen de ambos fundadores */}
          <Grid container spacing={4} alignItems="center" sx={{ marginBottom: 6 }}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                sx={{ fontFamily: "'Montserrat', sans-serif", color: 'maroon', fontWeight: '1000', textAlign: 'left', marginBottom: 2 }}
              >
                Juntos en Nuestra Misión
              </Typography>
              <Typography variant="body1" sx={{ color: 'white', fontFamily: "'Montserrat', sans-serif", fontSize: '1.2rem', lineHeight: 1.8 }}>
                Ambos fundadores comparten una visión común: crear vinos de la más alta calidad que no solo reflejan el
                carácter de nuestra tierra, sino también la pasión y dedicación que ponemos en cada botella. Unidos, están
                llevando a nuestra bodega a nuevas alturas, manteniendo siempre la autenticidad de nuestros procesos.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FounderImage src={founderTogether} alt="Fundadores juntos" />
            </Grid>
          </Grid>

          {/* Sección con la visión */}
          <Grid container spacing={4} alignItems="center"
            sx={{
                gap: {lg: "2vw"},
                marginBottom: {lg: "10vw"}
            }}
          >
            <Grid item xs={12} md={6}>
              <FounderImage src={vision} alt="Nuestra Visión" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                sx={{ fontFamily: "'Montserrat', sans-serif", color: 'maroon', fontWeight: '1000', textAlign: 'left', marginBottom: 2 }}
              >
                Nuestra Visión
              </Typography>
              <Typography variant="body1" sx={{ color: 'white', fontFamily: "'Montserrat', sans-serif", fontSize: '1.2rem', lineHeight: 1.8 }}>
                Nos enfocamos en mantener viva la tradición vitivinícola, pero con un enfoque fresco e innovador.
                Nuestra misión es llevar el vino a nuevas generaciones, conservando el respeto por los métodos antiguos
                pero sin miedo a probar cosas nuevas, siempre respetando nuestra tierra.
              </Typography>
            </Grid>
          </Grid>
          <NavbarB/>
          <Footer />
        </Box>
      </Box>
  );
};

export default AboutUs;
