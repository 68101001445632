import React, { useState, useEffect } from 'react';
import { ScrollContainer, ScrollPage } from "react-scroll-motion";
import Navbar from './components/Navbar';
import Video from './components/Video';
import Lawyer from './components/Lawyer';
import Bottle from './components/Bottle';
import Footer from './components/Footer';
import Title from './components/Title';
import Arrow from './components/Arrow';
import PhilosophySection from './components/PhilosophySection'; // Nuevo componente importado
import './App.css';
import ribera from './components/images/ribera.jpg';
import philosophy from './components/images/philosophy.jpg';
import Restaurants from "./components/Restaurants";
import AgePopUp from "./components/AgePopUp";

function App() {
  const [lawyers, setLawyers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scroll, setScroll] = useState(0);
  const [scrollValue, setScrollValue] = useState(375);

  useEffect(() => {
    const root = document.documentElement;
    const computedStyle = getComputedStyle(root);
    const value = parseInt(computedStyle.getPropertyValue('--video-scroll-value'), 10);
    setScrollValue(value);

    const handleScroll = () => {
      setScroll(window.scrollY);
      document.body.style.setProperty(
        "--scroll",
        window.scrollY / (document.body.offsetHeight - window.innerHeight)
      );
    };

    window.addEventListener('scroll', handleScroll);

    // Limpia el evento de scroll al desmontar el componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    fetch('http://217.160.115.139:8001/api/lawyers')
      .then(response => response.json())
      .then(data => {
        setLawyers(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching lawyers:', error);
        setLoading(false);
      });
  }, []);


  return (
    <div className="general">
      <ScrollContainer>
        <ScrollPage>
          <div className="logo-container">
            <div className="logo-animator">
                <Title />
            </div>
            <div className="video-animator">
                <div className={`header ${scroll > scrollValue ? 'video-inactive' : 'video-fixed'}`}>
                <Video />
              </div>
            </div>
          </div>
            <Arrow />
        </ScrollPage>

        <ScrollPage>
          <PhilosophySection
            title="Nuestra Filosofía"
            subtitle="Elaborar un vino de máxima calidad que represente lo mejor de nuestra tierra"
            alignment="left"
            img={ribera}
          />
        </ScrollPage>

        <ScrollPage>
          <PhilosophySection
            title="Ribera del Duero"
            subtitle="Elaborar un vino de máxima calidad que represente lo mejor de nuestra tierra"
            alignment="right"
            img={philosophy}
          />
        </ScrollPage>

        <div>
          {lawyers.map(lawyer => (
            lawyer.visibility ? (
              <ScrollPage key={lawyer.id} className="lawyer" style={{ minHeight: '100vh' }}>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                    <Lawyer id={lawyer.id} />
                )}
              </ScrollPage>
            ) : null
          ))}
        </div>

        <ScrollPage className="lawyer" style={{ minHeight: '100vh' }}>
            <Restaurants />
        </ScrollPage>
        <ScrollPage className="lawyer" style={{ minHeight: '100vh' }}>
            <Bottle />
        </ScrollPage>
      </ScrollContainer>

      <Navbar />
      <Footer />
      <AgePopUp/>
    </div>
  );
}

export default App;
