import React from 'react';
import { Card, CardMedia, CardContent, Typography, CardHeader, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import bottle from './images/bottle.jpg';
import { useNavigate } from 'react-router-dom'; // Para redirigir al componente Shop

const Bottle = () => {
  const navigate = useNavigate(); // Hook para la navegación

  const handleCardClick = () => {
    navigate('/shop'); // Redirige al componente Wine
  };

  const handleCartClick = () => {
    navigate('/shop'); // Redirige al componente Shop
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
      <Card
        sx={{
          maxWidth: 500,
          width: '100%',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
          borderRadius: '16px',
          overflow: 'hidden',
          position: 'relative',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
            cursor: 'pointer', // Mostrar un puntero para indicar que es clickable
          }
        }}
         // Evento para hacer clic y redirigir a Wine
      >
        <CardHeader
          onClick={handleCardClick}
          title={
            <Typography
              variant="h4"
              sx={{
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 'bold',
                color: '#3f3d56',
                textAlign: 'center'
              }}
            >
              Tienda online
            </Typography>
          }
        />
        <CardMedia
          onClick={handleCardClick}
          component="img"
          image={bottle}
          alt="Nuestra Botella"
          sx={{ height: 400, objectFit: 'cover' }}
        />
        <CardContent>
          <Typography
            variant="h6"
            sx={{
              fontFamily: 'Montserrat, sans-serif',
              color: '#5c0c0c',
              textAlign: 'center',
              letterSpacing: '1px',
              fontWeight: '100'
            }}
          >
            Vendimiado a mano
          </Typography>
        </CardContent>

        {/* IconButton con redirección a Shop */}
        <IconButton
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 16,
            color: '#5c0c0c'
          }}
          aria-label="add to cart"
          onClick={handleCartClick} // Redirige a Shop
        >
          <ShoppingCartIcon />
        </IconButton>
      </Card>
    </div>
  );
};

export default Bottle;
