import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import photo1 from './images/phil1.jpg'; // Importar las imágenes
import photo2 from './images/phil4.jpg';
import photo3 from './images/phil3.jpg';
import photo4 from './images/phil2.jpg';

// Estilo para el texto superpuesto
const OverlayText = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontFamily: "'Playfair Display', serif",
  fontWeight: 'bold',
  fontSize: '2rem',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  textShadow: '2px 2px 10px rgba(0, 0, 0, 0.7)',
  zIndex: 2,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translate(-50%, -50%) scale(1.1)',
    color: 'gold', // Cambia a dorado en hover
  },
}));

// Estilo para las imágenes
const ImageWrapper = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '8px',
  transition: 'transform 0.3s ease-in-out, filter 0.3s ease-in-out',
  '&:hover img': {
    transform: 'scale(1.1)', // Zoom al hacer hover
    filter: 'brightness(0.8)', // Oscurecer la imagen en hover
  },
});

const Philosophy = () => {
  return (
    <Box sx={{ backgroundColor: '#f0f0f0', padding: 4, minHeight: '100vh' }}>
      {/* Título de la sección */}
      <Typography
        variant="h2"
        sx={{
          color: 'black',
          fontFamily: "'Playfair Display', serif",
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 4,
        }}
      >
        Nuestra Filosofía
      </Typography>

      {/* Grid para mostrar las imágenes y texto superpuesto */}
      <Grid container spacing={4}>
        {/* Imagen 1 */}
        <Grid item xs={12} md={6}>
          <ImageWrapper>
            <img
              src={photo1}
              alt="Filosofía 1"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '8px',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
            <OverlayText>
              Compromiso con la Calidad
            </OverlayText>
          </ImageWrapper>
        </Grid>

        {/* Imagen 2 */}
        <Grid item xs={12} md={6}>
          <ImageWrapper>
            <img
              src={photo2}
              alt="Filosofía 2"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '8px',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
            <OverlayText>
              Respeto por la Tierra
            </OverlayText>
          </ImageWrapper>
        </Grid>

        {/* Imagen 3 */}
        <Grid item xs={12} md={6}>
          <ImageWrapper>
            <img
              src={photo3}
              alt="Filosofía 3"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '8px',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
            <OverlayText>
              Innovación Constante
            </OverlayText>
          </ImageWrapper>
        </Grid>

        {/* Imagen 4 */}
        <Grid item xs={12} md={6}>
          <ImageWrapper>
            <img
              src={photo4}
              alt="Filosofía 4"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '8px',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
            <OverlayText>
              Tradición y Pasión
            </OverlayText>
          </ImageWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Philosophy;
